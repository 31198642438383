<template>
  <div>
    <div class="mb-3 d-flex align-center justify-space-between">
      <div class="w-40per">
        <select-pos-model
          v-model="idPos"
          :label="$t('labels.pos')"
          custom-class="mr-2"
          @change="focusInputQr"
        ></select-pos-model>
      </div>
      <div class="w-60per">
        <input-qr-scan-model
          ref="inputQr"
          v-model="code"
          :label="label"
          @keyupEnter="inputScan"
        ></input-qr-scan-model>
      </div>
    </div>

    <div>
      <v-simple-table class="table-padding-2-no-top">
        <tbody>
          <tr>
            <td class="font-weight-medium" style="width: 70px">Sọt:</td>
            <td
              colspan="2"
              style="font-size: 20px"
              class="primary--text font-weight-medium"
            >
              {{ basket.code }}
            </td>
          </tr>
          <tr>
            <td class="font-weight-medium">Tên:</td>
            <td>{{ goods.name }}</td>
            <td
              rowspan="6"
              style="
                width: 98px;
                border-bottom: 0;
                vertical-align: top;
                padding-top: 16px;
              "
              class="text-center"
            >
              <ImageViewer
                width="80px"
                height="auto"
                v-if="goods.url_images"
                :url="goods.url_images"
              />
            </td>
          </tr>
          <tr>
            <td class="font-weight-medium">Đặc tả:</td>
            <td>{{ goods.description }}</td>
          </tr>
          <tr>
            <td class="font-weight-medium">Size:</td>
            <td>{{ goods.size }}</td>
          </tr>
          <tr>
            <td class="font-weight-medium">Barcode:</td>
            <td>{{ goods.customer_goods_barcode }}</td>
          </tr>
          <tr v-if="goods.process_exp_date">
            <td class="font-weight-medium">HSD:</td>
            <td>
              <v-row dense>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="goods.year"
                    :items="yearOptions"
                    class="c-input-xs"
                    :label="$t('labels.expired_year')"
                    dense
                    outlined
                    clearable
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="goods.month"
                    :items="monthOptions"
                    class="c-input-xs"
                    :label="$t('labels.expired_month')"
                    dense
                    outlined
                    clearable
                    hide-details
                    :disabled="!goods.year"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="goods.day"
                    :items="dayOptions"
                    :disabled="!goods.month || !goods.year"
                    class="c-input-xs"
                    :label="$t('labels.expired_day')"
                    dense
                    outlined
                    clearable
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="goods.lot_tracking"
                    class="c-input-xs"
                    type="text"
                    :label="$t('labels.lot')"
                    dense
                    outlined
                    clearable
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </td>
          </tr>
          <tr>
            <td class="font-weight-medium">In Tem:</td>
            <td>
              <v-text-field
                v-model.number="printQuantity"
                class="c-input-small"
                :readonly="!!goods.insert_tracking"
                :disabled="isDisabledInputStamp"
                label="Số lượng tem"
                placeholder="Tối đa 50"
                append-icon="mdi-printer"
                type="number"
                @click:append="generateGoodsStamp"
                @keyup.enter="generateGoodsStamp"
                ref="inputPrintQuantity"
                dense
                outlined
                hide-details
              ></v-text-field>
            </td>
          </tr>
          <tr>
            <td class="font-weight-medium">Thực nhận:</td>
            <td>
              <v-text-field
                v-model.number="receiptQuantity"
                class="c-input-small"
                label="Số lượng thực nhận"
                placeholder=""
                :disabled="!printQuantity || isDisabledInputStamp"
                type="number"
                ref="inputReceiptQuantity"
                dense
                outlined
                hide-details
              ></v-text-field>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <v-btn
                color="success"
                small
                block
                @click="checkBeforeReceipt"
                :disabled="isDisabledBtn"
              >
                {{ $t("labels.confirm") }}
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>

    <v-dialog v-model="dialogRollUid" persistent max-width="350px">
      <ReceiptDirectRollUid
        v-if="dialogRollUid"
        :goods="goods"
        :insert-tracking="goods.insert_tracking"
        :receipt-quantity="receiptQuantity"
        :print-quantity="printQuantity"
        :uids="goods.uids"
        @cancel="cancelRollUid"
        @confirm="confirmRollUid"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import moment from "moment/moment";
const CURRENT_YEAR = moment().year();
const CURRENT_MONTH = moment().month() + 1;
const CURRENT_DAY = moment().date();

export default {
  name: "ReceiptDirect",
  components: {
    ImageViewer: () => import("@/components/common/ImageViewer"),
    ReceiptDirectRollUid: () =>
      import("@/components/pos_goods/ReceiptDirectRollUid"),
  },
  data: () => ({
    isLoading: false,
    dialogRollUid: false,
    qrScanType: null,
    idPos: null,
    step: 1,
    code: null,
    printQuantity: null,
    receiptQuantity: null,
    items: [],
    posOptions: [],
    goods: {},
    basket: {},
  }),
  computed: {
    label() {
      switch (this.step) {
        case 1:
          return this.$t("labels.basket_code");
        case 2:
          return this.$t("labels.barcode");
        default:
          return "";
      }
    },
    isDisabledBtn() {
      return (
        !this.printQuantity ||
        this.printQuantity < this.receiptQuantity ||
        !this.basket.id
      );
    },
    dayOptions() {
      if (!this.goods.year || !this.goods.month) {
        return [];
      }
      const dayFrom =
        this.year == CURRENT_YEAR && +this.month == CURRENT_MONTH
          ? CURRENT_DAY
          : 1;
      const days = [];
      for (let i = dayFrom; i <= 31; i++) {
        const dayVal = i < 10 ? `0${i}` : `${i}`;
        const checkDay = moment(
          `${this.goods.year}-${this.goods.month}-${dayVal}`
        );
        if (checkDay.isValid()) {
          days.push({
            value: dayVal,
            text: dayVal,
          });
        }
      }
      return days;
    },
    monthOptions() {
      if (!this.goods.year) {
        return [];
      }
      const months = [];
      const monthFrom = this.goods.year == CURRENT_YEAR ? CURRENT_MONTH : 1;
      for (let i = monthFrom; i <= 12; i++) {
        const monthVal = i < 10 ? `0${i}` : `${i}`;
        months.push({
          value: monthVal,
          text: monthVal,
        });
      }
      return months;
    },
    yearOptions() {
      let years = [];
      for (let i = CURRENT_YEAR; i < CURRENT_YEAR + 9; i++) {
        years.push({
          value: `${i}`,
          text: `${i}`,
        });
      }
      return years;
    },
    isDisabledInputStamp() {
      return (
        !this.goods.id ||
        !this.basket.id ||
        (this.goods.process_exp_date &&
          (!this.goods.year || !this.goods.month || !this.goods.day))
      );
    },
  },
  mounted() {
    this.getPos();
    this.getProcessing();
  },
  methods: {
    focusInputQr() {
      this.$refs.inputQr.focusInput();
    },
    async getProcessing() {
      const { data } = await httpClient.post("/pos-get-current-receipt-direct");
      if (data && data.goods) {
        this.goods = { ...data.goods };
        this.idPos = data.id_pos;
        this.printQuantity = this.goods.uids.length || null;
        this.$vToastify.warning(
          `Có ${this.printQuantity} tem của ${this.goods.customer_goods_barcode} đang xử lý! Cần xử lý xong đã nhé!`
        );
        setTimeout(() => {
          this.$refs.inputReceiptQuantity.focus();
        }, 500);
      }
    },
    getPos() {
      const { poss } = window.me;
      this.posOptions = [...poss].map((w) => ({
        value: w.id,
        text: w.code || "",
      }));
      if (this.posOptions.length === 1) {
        this.idPos = this.posOptions[0].value;
      }
    },
    resetValue() {
      this.step = 1;
      this.basket = {};
      this.goods = {};
      this.code = null;
      this.printQuantity = null;
      this.receiptQuantity = null;
    },
    inputScan() {
      if (!this.code) {
        return false;
      }

      switch (this.step) {
        case 1:
          return this.scanBasket();
        case 2:
          return this.scanGoodsAndFocusQuantity();
        default:
          this.$vToastify.error("Wrong!");
      }
    },
    async printStampAgain(id_goods, insert_tracking) {
      await this.downloadExcelFile(
        `${process.env.VUE_APP_API_V2_URL}/pos-generate-goods-stamp`,
        {
          id_goods: id_goods,
          insert_tracking: insert_tracking,
        },
        `50x20_${insert_tracking}.pdf`
      );
    },
    async getGoods() {
      const { data } = await httpClient.post("/get-goods-by-barcode", {
        customer_goods_barcode: this.code,
      });
      return data;
    },
    async generateGoodsStamp() {
      if (this.printQuantity > 50) {
        this.$vToastify.error("Số lượng in tem tối đa 50/lần");
      }
      if (this.goods.insert_tracking) {
        return await this.printStampAgain(
          this.goods.id,
          this.goods.insert_tracking
        );
      }
      const { data } = await httpClient.post(
        "/pos-receipt-direct-generate-goods-stamp",
        {
          id_goods: this.goods.id,
          id_basket: this.basket.id,
          quantity: this.printQuantity,
          code: this.code || this.goods.sku || null,
          expired_date: this.goods.process_exp_date
            ? `${this.goods.year}-${this.goods.month}-${this.goods.day}`
            : null,
          id_pos: this.idPos,
        }
      );
      this.goods = {
        ...this.goods,
        uids: data.uids,
        insert_tracking: data.insert_tracking,
      };

      setTimeout(() => {
        this.$refs.inputReceiptQuantity.focus();
      }, 500);

      return await this.printStampAgain(
        this.goods.id,
        this.goods.insert_tracking
      );
    },
    showDialogRollUid() {
      this.dialogRollUid = true;
    },
    cancelRollUid() {
      this.dialogRollUid = false;
    },

    confirmRollUid(roll_uids) {
      this.goods = { ...this.goods, roll_uids };
      this.dialogRollUid = false;
      this.checkBeforeReceipt();
    },
    checkBeforeReceipt() {
      const rollLength = (this.goods.roll_uids || []).length;
      if (
        this.printQuantity === this.receiptQuantity + rollLength ||
        this.receiptQuantity === 0
      ) {
        this.receiptDirectMultiple();
      } else {
        if (this.receiptQuantity > this.printQuantity) {
          this.$vToastify.error(
            "Số lượng thực nhận không thể lớn hơn số lượng tem đã in!"
          );
        } else {
          this.showDialogRollUid();
        }
      }
    },
    async receiptDirectMultiple() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/pos-receipt-direct-multiple", {
          id_goods: this.goods.id,
          id_basket: this.basket.id,
          quantity: this.receiptQuantity,
          id_pos: this.idPos,
          insert_tracking: this.goods.insert_tracking,
          roll_uids: this.goods.roll_uids,
        });
        this.resetValue();
        this.code = null;
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.goods_receipt_success"));
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
      }
    },
    async scanGoodsAndFocusQuantity() {
      if (this.code.includes("@")) {
        this.$vToastify.error("Hàng hóa đã có tem! Chỉ có thể nhập lẻ!");
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const goods = await this.getGoods();
        this.goods = { ...goods };
        this.printQuantity = null;
        this.receiptQuantity = null;

        setTimeout(() => {
          this.$refs.inputPrintQuantity.focus();
        }, 500);

        this.code = null;
        this.isLoading = false;
        this.$root.$emit("playSuccessAudio");
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
        this.$root.$emit("playErrorAudio");
      }
    },
    async scanBasket() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post("/pos-get-basket-for-receipt", {
          code: this.code,
        });
        this.basket = { ...data };
        this.step = 2;
        this.code = null;
        this.isLoading = false;
        this.$root.$emit("playSuccessAudio");
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
        this.$root.$emit("playErrorAudio");
      }
    },
  },
};
</script>

<style scoped></style>
